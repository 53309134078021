import { Col, Row, Container, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SelectComponentController from '../../../../components/selectComponent/indexController';
import InputColorPickerController from './InputColorPicker/indexController';
import AttachmentsInMessageController from '../../../../homeMessageTrigger/components/attachmentsInMessage/indexController';
import { Channels } from '../../../../../../core/enums/channels';
import { IconInfoCircle } from '@tabler/icons-react';
import Loading from '../../../../../../components/loading';

const TrainBot = ({
  t,
  props,
  dataBot,
  values,
  iframeUrl,
  setIframeUrl,
  urlNotTest,
  copyToClipboard,
  channelId,
  iframeRef,
  options,
  behavior,
  setBehavior,
  nameColor,
  setNameColor,
  headerColor,
  setHeaderColor,
  backgroundColor,
  setBackgroundColor,
  consumerMessageColor,
  setConsumerMessageColor,
  agentColor,
  setAgentColor,
  sendButtonColor,
  setSendButtonColor,
  onsubmit,
  setAttachmentId,
  previewImageURL,
  setPreviewImageURL,
  isLoading
}) => {
  return (
    <>
      <Container className='nopadding'>
        <Loading visibility={isLoading}></Loading>
        <Row className="my-3">
          {props.channelId === Channels.Webchat &&
            <Col md={4} 
              style={{ border: '1px solid #EEF3FF', overflowY: "scroll", height: "75vh" }}
            >
                <Row  
                  style={{
                    textAlign: 'left',
                    font: "normal 500 normal min(max(10.5px, calc(0.2em + 0.9vw)), 20px) 'Montserrat'",
                    color: '#4A69BD'
                  }}
                  className='ms-1 mt-3'
                >
                  { t("configurations.chatbots.view.custom_chat_bot.chat_customization") }
                </Row>
                <Row className='mt-2'>
                  <Col>
                    <Row className="mx-1 mb-1 mt-1"
                      style={{
                        font: "normal 400 normal min(max(10px, calc(0.2em + 0.65vw)), 15px) 'Montserrat'",
                        color: "#707070",
                      }}
                    >
                      { t("configurations.chatbots.view.custom_chat_bot.behavior") }
                    </Row>
                    <Row>
                      <SelectComponentController 
                        options={options} 
                        setOptions={() => {}} 
                        selecteds={behavior} 
                        setSelecteds={setBehavior} 
                        singleSelect={true} 
                        isFormNoFilter={true} 
                        title={t("configurations.chatbots.view.custom_chat_bot.behavior")} 
                        overrideStrings={{}} 
                        hasCreateItem={false} 
                        hasNullOption={false} 
                        hasAllOption={false} 
                        disableSearch
                      />
                    </Row>
                  </Col>
                </Row>
                <Row className='mt-2'>
                    <Col md={9} className='px-0'>
                      <AttachmentsInMessageController 
                        modalVisibility={false} 
                        selectedChannelId={Channels.Webchat} 
                        setAttachmentsFilesIds={setAttachmentId} 
                        attachmentType={'icon'}
                        customTitle={
                          <div> 
                            <span>{ t("configurations.chatbots.view.custom_chat_bot.logo") }</span>
                            <span className='ms-1'>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    { t("configurations.chatbots.view.custom_chat_bot.select_file") }
                                  </Tooltip>
                                }
                              >
                                <IconInfoCircle width={20} height={20} color='#707070' />
                              </OverlayTrigger>
                            </span>
                          </div>
                        } 
                        customDropMessage={t("configurations.chatbots.view.custom_chat_bot.custom_drop_message")}
                        setPreviewImageURL={setPreviewImageURL}
                        setDisableButtonSave={() => {}}
                      />
                    </Col>
                    <Col className='d-flex align-items-end'>
                      <div style={{ height: '9vh', borderRadius: "5px", border: "1px solid #E5ECFF" }} className='nopadding'>
                        <img src={previewImageURL || "/mico.svg"} className="" style={{ backgroundColor: '#fff', borderRadius: '50%', padding: '2px' }} alt="" width={'100%'} height={"100%"} />
                      </div>
                    </Col>
                </Row>
                <Row className='mt-2'>
                  <Col>
                    <Row className="mx-1 mb-1 mt-1"
                      style={{
                        font: "normal 400 normal min(max(10px, calc(0.2em + 0.65vw)), 15px) 'Montserrat'",
                        color: "#707070",
                      }}
                    >
                      { t("configurations.chatbots.view.custom_chat_bot.name_color") }
                    </Row>
                    <Row className="mx-1 mb-1 mt-1">
                      <InputColorPickerController color={nameColor} setColor={setNameColor} />
                    </Row>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col>
                    <Row className="mx-1 mb-1 mt-1"
                      style={{
                        font: "normal 400 normal min(max(10px, calc(0.2em + 0.65vw)), 15px) 'Montserrat'",
                        color: "#707070",
                      }}
                    >
                      { t("configurations.chatbots.view.custom_chat_bot.header_color") }
                    </Row>
                    <Row className="mx-1 mb-1 mt-1">
                      <InputColorPickerController color={headerColor} setColor={setHeaderColor} />
                    </Row>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col>
                    <Row className="mx-1 mb-1 mt-1"
                      style={{
                        font: "normal 400 normal min(max(10px, calc(0.2em + 0.65vw)), 15px) 'Montserrat'",
                        color: "#707070",
                      }}
                    >
                      { t("configurations.chatbots.view.custom_chat_bot.background_color") }
                    </Row>
                    <Row className="mx-1 mb-1 mt-1">
                      <InputColorPickerController color={backgroundColor} setColor={setBackgroundColor} />
                    </Row>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col>
                    <Row className="mx-1 mb-1 mt-1"
                      style={{
                        font: "normal 400 normal min(max(10px, calc(0.2em + 0.65vw)), 15px) 'Montserrat'",
                        color: "#707070",
                      }}
                    >
                      { t("configurations.chatbots.view.custom_chat_bot.consumer_message_color") }
                    </Row>
                    <Row className="mx-1 mb-1 mt-1">
                      <InputColorPickerController color={consumerMessageColor} setColor={setConsumerMessageColor} />
                    </Row>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col>
                    <Row className="mx-1 mb-1 mt-1"
                      style={{
                        font: "normal 400 normal min(max(10px, calc(0.2em + 0.65vw)), 15px) 'Montserrat'",
                        color: "#707070",
                      }}
                    >
                      { t("configurations.chatbots.view.custom_chat_bot.agent_color") }
                    </Row>
                    <Row className="mx-1 mb-1 mt-1">
                      <InputColorPickerController color={agentColor} setColor={setAgentColor} />
                    </Row>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col>
                    <Row className="mx-1 mb-1 mt-1"
                      style={{
                        font: "normal 400 normal min(max(10px, calc(0.2em + 0.65vw)), 15px) 'Montserrat'",
                        color: "#707070",
                      }}
                    >
                      { t("configurations.chatbots.view.custom_chat_bot.send_button_color") }
                    </Row>
                    <Row className="mx-1 mb-1 mt-1">
                      <InputColorPickerController color={sendButtonColor} setColor={setSendButtonColor} />
                    </Row>
                  </Col>
                </Row>
                <Row className='mt-4 mb-4'>
                  <Col className='ms-1'>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip>
                            { t("configurations.chatbots.view.bot_info.incorporate_chat") }
                          </Tooltip>
                        }
                      >
                        <Button onClick={copyToClipboard} className='buttonGrayChatbot'>{ t("configurations.chatbots.view.bot_info.copy_code") }</Button>
                      </OverlayTrigger>
                  </Col>
                  <Col className='me-1'>
                    <Button onClick={onsubmit} className='buttonBlue'>{ t("configurations.chatbots.view.custom_chat_bot.save") }</Button>
                  </Col>
                </Row>
            </Col>
          }
          <Col className='ms-3'
            md={props.channelId !== Channels.Webchat? 9: null}
            style={{ border: '1px solid #EEF3FF' }}
          >
            {props.channelId === Channels.Webchat &&
              <Row
                style={{
                  textAlign: 'left',
                  font: "normal 500 normal min(max(10.5px, calc(0.2em + 0.9vw)), 20px) 'Montserrat'",
                  color: '#4A69BD'
                }}
                className='ms-1 mt-3'
              >
                { t("configurations.chatbots.view.custom_chat_bot.preview") }
              </Row>
            }
            <Row className='mt-2 mb-3 ms-1'
              style={{
                textAlign: 'left',
                font: 'var(--px15_19_Bold-font)',
                letterSpacing: '0px',
                color: '#707070',
                opacity: '1',
              }}
            >
              { t("configurations.chatbots.view.bot_info.chat_test_title") }
              {
                <span style={{ color: 'red', marginLeft: '-0.75rem' }}>{t("configurations.chatbots.view.bot_info.chat_test_warn")}</span>
              }
            </Row>
            <iframe ref={iframeRef} name="train_bot_iframe_config" style={{ height: '60vh', width: '100%' }} onLoad={() => { props.setIsLoading(false) }} src={iframeUrl}></iframe>
          </Col>
        </Row>
        {/* {dataBot?.state && (dataBot?.state?.channel === "4a7b71bb-6432-4794-ad11-f978b067871d") && values.company.contractStatusId === ContractStatus.Active ?
          <Row className="d-flex justify-content-start" style={{ marginTop: '-10vh' }}>
            <Col md={6}>
              <Form.Label htmlFor="name">{ t("configurations.chatbots.view.bot_info.incorporate_chat") }</Form.Label>
              <InputGroup className="mb-2">
                <FormControl className="form-control-Default"
                  placeholder={ t("configurations.chatbots.view.bot_info.incorporate_chat") }
                  aria-label={ t("configurations.chatbots.view.bot_info.incorporate_chat") }
                  aria-describedby={ t("configurations.chatbots.view.bot_info.incorporate_chat") }
                  size='lg'
                  type='name'
                  id="embed_chat"
                  disabled={true}
                  value={`<iframe src="${urlNotTest}"></iframe>`}
                >
                </FormControl>
                <Button className="buttonGrayChatbotCopy" onClick={copyToClipboard}>{ t("configurations.chatbots.view.bot_info.copy_code") }</Button>
              </InputGroup>
            </Col>
          </Row>
          :
          <></>
        } */}
      </Container>
    </>
  );
}

export default TrainBot;