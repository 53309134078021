import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';

import { getToken } from '../../../store/token'
import { getIdCompany } from '../../../store/company'
import { getIdEmployee } from '../../../store/employee'
import { getIdUser } from '../../../store/user'

import {
    setRegisterCustomersModal,
    getShowCreateConsumersModal,
    getShowEditConsumersModal,
    getShowConfirmationModal,
    getSortByList,
    getOrderByList,
    getShowRegisterCustomersModal,
    setShowModalConsumersSimilarData,
    getShowModalConsumersSimilarData,
} from '../../../store/internal'

import { AppRequesterController } from '../../../services/appRequester/appRequesterController';
import BackendConstants from '../../../core/constants/backend-constants';
import { ListName } from '../../../core/enums/order-sort-by';
import { TokenInterface } from '../../../services/requestsInterfacesModel';
import { ConsumerData } from './indexModel';
import UserService from '../../../services/user-service';
import HomeConsumers from '.';
import { useTranslation } from 'react-i18next';

const AppRequesterConst = new AppRequesterController();

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        name?: string;
    }
};

const HomeConsumersController = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowCreateConsumersModal: useSelector(getShowCreateConsumersModal),
            getShowEditConsumersModal: useSelector(getShowEditConsumersModal),
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
            getSortByList: useSelector(getSortByList),
            getOrderByList: useSelector(getOrderByList),
            getShowRegisterCustomersModal: useSelector(getShowRegisterCustomersModal),
            getShowModalConsumersSimilarData: useSelector(getShowModalConsumersSimilarData),
        }
    };

    const [isLoading, setIsLoading] = useState(false);
    const [currentConsumers, setCurrentConsumers] = useState(undefined);
    const [/*currentConsumer*/, setCurrentConsumer] = useState([]);
    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);
    const [currentParams, setCurrentParams] = useState({});
    const [aux, setAux] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentLimit, /*SetCurrentLimit*/] = useState(BackendConstants.limitMin);
    const [currentHasMoreInformation, setCurrentHasMoreInformation] = useState(true);
    const [currentState, setCurrentState] = useState("");

    const [openModalPhone, setOpenModalPhone] = useState(undefined);
    const [phoneNumber, /*setPhoneNumber*/] = useState("");

    const [currentConsumerForSimilarData, setCurrentConsumerForSimilarData] = useState(null);
    const [similarConsumers, setSimilarConsumers] = useState(undefined);
    const { t } = useTranslation()

    useEffect(() => {
        if (values.internal.getShowRegisterCustomersModal) {
            dispatch(setRegisterCustomersModal(false));
        }
    }, []);

    // quando o componente é montado os modais devem estar com visibilidade desabilitada
    useEffect(() => {
        if (values.internal.getShowCreateConsumersModal) {
            dispatch(setRegisterCustomersModal(false));
        }

        /* if (values.internal.getShowEditConsumersModal) {
            dispatch(setShowEditConsumersModal(false));
        } */

        if (values.internal.getShowModalConsumersSimilarData) {
            dispatch(setShowModalConsumersSimilarData(false));
        }
    }, []);

    useEffect(() => {
        const controller = new AbortController();

        if (values.token.value === null) {
        } else {
            // let 
            // if (values.internal.getSortByList !== null && values.internal.getOrderByList !== null) {
            //     props.callbackOrderBy(props., values.internal.getOrderByList);
            // }

            // busca do consumidor, por enquanto vem da página de unficação
            let consumerParams;
            if (location?.state && location.state["search"] !== "" && location.state['search'] !== undefined) {
                setCurrentFilterSearch(location.state["search"]);
                consumerParams = { limit: currentLimit, page: currentPage, search: location.state['search'] };
            } else {
                consumerParams = { limit: currentLimit, page: currentPage };
            }

            setCurrentParams((params) => {
                setCurrentState((state) => {
                    getAllConsumersToPagination(Object.assign(currentParams, consumerParams), controller);
                    return state;
                });
                return params;
            });
        }

        return () => {
            controller.abort();
        }
    }, [currentPage, currentState]);

    const getAllConsumers = async (params: { limit: number; page: number; }, controller: AbortController) => {
        const headers = UserService.getHeaders(), signal = controller.signal;

        // if (values.internal.getSortByList.listName === ListName.ListOfConsumers && values.internal.getSortByList.sort_by !== null && values.internal.getOrderByList !== null) {
        //     params = Object.assign(params, { sort_by: values.internal.getSortByList.sort_by, order_by: values.internal.getOrderByList });
        // }

        await AppRequesterConst.Get(
            '/consumer', { headers, signal, params: params },
            (response: Object) => { },
            (data: ConsumerData) => {
                if (data.status === 200 && data) {
                    setCurrentHasMoreInformation(true);
                    setAux((state) => {
                        let aux = state.concat(data.data.consumers);
                        setAux(aux);
                        setCurrentConsumers(aux);
                        return state;
                    });
                } else if (aux.length === 0) {
                    setCurrentConsumers(undefined);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }


    const updateListfunction = () => {
        setCurrentPage(currentPage + 1);

    };

    const updateListAfterDelete = () => {
        setAux([]);
        setCurrentPage(1);

        setCurrentState("deleting");

        if (currentState === "deleting") {
            setCurrentState("");
        }
    };

    const search = (e: any, auxObj: Object, action: string) => {
        setCurrentPage(1);
        setAux([]);

        let name = "";
        if (e.currentTarget) {
            name = e.currentTarget.name;
        } else if (e.target) {
            name = e.target.name;
        }

        if (name === "cleanSearchButtonName" || action === "clean") {
            setCurrentFilterSearch("");
            auxObj = {};
            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }
        } else if (name === "searchButtonName" || name === "searchInputName") {
            auxObj = {};

            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch;
            }
        } else {
            setCurrentState("filtering");

            if (currentState === "filtering") {
                setCurrentState("");
            }
        }

        setCurrentParams((prevParams) => ({ ...prevParams, ...auxObj }));
    }

    const cleanList = () => {
        setCurrentParams({});
        setCurrentPage(1);
        setAux([]);

        setCurrentState("filtering");

        if (currentState === "filtering") {
            setCurrentState("");
        }
    }

    const setCurrentConsumerFunction = (value: any) => {
        setCurrentConsumer(value);
    }

    const getAllConsumersToPagination = (params: { limit: number; page: number; }, controller: AbortController) => {
        getAllConsumers(params, controller);
    }

    const showCreateConsumer = () => {
        setCurrentPage(1);
        // setAux([]);
        dispatch(setRegisterCustomersModal(true));
    }

    const callbackModalCustomers = () => {
        setCurrentPage(1);
        setAux([]);
        const controller = new AbortController();
        getAllConsumersToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), controller);
    }

    const callbackOrderByConsumers = (column: any, order: any) => {
        setCurrentPage(1);
        setAux([]);
        const auxObj = {};

        if (column != null) {
            auxObj["sort_by"] = column;
        }

        if (order != null) {
            auxObj["order_by"] = order;
        }
        setCurrentState(column + order);
        setCurrentParams((prevParams) => ({ ...prevParams, ...auxObj }));
        document.getElementById("ListOfConsumers").scrollTop = 0;
    }

    return <HomeConsumers
        t={t}
        values={values}
        isLoading={isLoading}
        phoneNumber={phoneNumber}
        openModalPhone={openModalPhone}
        setOpenModalPhone={setOpenModalPhone}
        currentConsumers={currentConsumers}
        currentHasMoreInformation={currentHasMoreInformation}
        setCurrentFilterSearch={setCurrentFilterSearch}
        currentFilterSearch={currentFilterSearch}
        setCurrentConsumerForSimilarData={setCurrentConsumerForSimilarData}
        setSimilarConsumers={setSimilarConsumers}
        similarConsumers={similarConsumers}
        currentConsumerForSimilarData={currentConsumerForSimilarData}
        search={search}
        showCreateConsumer={showCreateConsumer}
        updateListAfterDelete={updateListAfterDelete}
        updateListfunction={updateListfunction}
        setCurrentConsumerFunction={setCurrentConsumerFunction}
        callbackModalCustomers={callbackModalCustomers}
        callbackOrderByConsumers={callbackOrderByConsumers}
        currentState={currentState}
        setCurrentState={setCurrentState}
        cleanList={cleanList}
    />

}

export default HomeConsumersController;