import { Formik } from "formik"
import { useEffect, useState } from "react"
import { Modal, Form, Row, Col, InputGroup, FormControl, FormLabel, Button, Spinner } from "react-bootstrap"
import styles from '../styles'
import SelectComponent from "../../selectComponent"
import { Brokers } from "../../../../../core/enums/brokers"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { AppRequesterControlPanelController } from "../../../../../services/appRequesterControlPanel/appRequesterControlPanelController"
import UserService from "../../../../../services/user-service"
import { setShowAlertFeedback } from "../../../../../store/internal"
import "./style.scss"
import allStyles from "../../../../homeApp/components/chatEditor/styles"
import { useDropzone } from "react-dropzone"
import { Channels } from "../../../../../core/enums/channels"
import { verifyCode } from "../../../../../services/codeCxpressInterface"
import { useTranslation } from "react-i18next"

interface BrokerInterface {
    label: string,
    value: string
}

const ModalEditBroker = ({ show, onHide, currentData, currentWhatsappId }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [validated, setValidated] = useState(false)

    const [hasBot, setHasbot] = useState(false)
    const [selectedBroker, setSelectedBrokers] = useState([])

    const [onSelectBroker, setOnSelectBroker] = useState([])
    
    const [Webhook, setWebhook] = useState("")
    const [description, setDescription] = useState("")
    const [email, setEmail] = useState("")
    const [addres, setAddres] = useState("")
    const [urls, setUrls] = useState(['']);
    
    const [showFeedbackWebhook, setShowFeedbackWebhook] = useState(false)
    
    const [showFeedbackAddres, setShowFeedbackAddres] = useState(false)
    const [showFeedbackDescription, setShowFeedbackDescription] = useState(false)
    const [showFeedbackEmail, setShowFeedbackEmail] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const AppRequesteConst = new AppRequesterControlPanelController()

    const [optionsBroker, setOptionsBroker] = useState([
        { label: "Twilio", value: Brokers.Twilio },
        { label: "Positus", value: Brokers.Positus },
        { label: "Meta", value: Brokers.Meta },
        { label: "Smarters", value: Brokers.Smarters }
    ])

    useEffect(() => {
        setShowFeedbackEmail(false)
        setShowFeedbackWebhook(false)
        setShowFeedbackAddres(false)
        setShowFeedbackDescription(false)
        setWebhook("")
        setAddres("")
        setEmail("")
        setDescription("")
        setUrls([''])
    }, [])

    useEffect(() => {
        if (currentData) {
            setWebhook(currentData?.broker_config?.webhook)
            setAddres(currentData?.broker_config?.address)
            setEmail(currentData?.broker_config?.email)
            setDescription(currentData?.broker_config?.description)
            setUrls(currentData.broker_config?.websites || [''])
            // setSelectBrokerId(currentWhatsappData?.whatsAppKey?.broker_id)
        }
    }, [currentData])


    const [errors, setErrors] = useState(['']);

    const handleChangeUrl = (index, event) => {
        const newUrls = [...urls];
        newUrls[index] = event.target.value;
        setUrls(newUrls);
    };

    const addUrl = () => {
        if (urls.length < 2) {
            setUrls([...urls, '']);
            setErrors([...errors, '']);
        }
    };

    const removeUrl = (index) => {
        const newUrls = urls.filter((_, i) => i !== index);
        const newErrors = errors.filter((_, i) => i !== index);
        setUrls(newUrls);
        setErrors(newErrors);
    };

    const validateUrl = (url) => {
        return !/^(http:\/\/|https:\/\/|$)/i.test(url) 
    }

    const [files, setFiles] = useState([]);

    const deleteFile = (fileName: any[]) => {
        let newFiles = files.filter((item) => item.path !== fileName);
        acceptedFiles.forEach((file, i) => { acceptedFiles.pop() });
        setIsClicked(false);
        setFiles(newFiles);
    }

    const [isClicked, setIsClicked] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [file_id, setFile_id] = useState(null as string || null);
    const [errorSelectRecipients, setErrorSelectRecipients] = useState(false);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxSize: 5242880,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png']
        },
        noClick: isClicked,
        noDrag: isClicked,
        onDrop: async (file) => {
            let img = new Image();
            img.src = URL.createObjectURL(file[0]);
            await img.decode()
            if (img.height < 640 || img.width < 640) {
                dispatch(setShowAlertFeedback({ message: "Imagem deve ser no mínimo 640 x 640", visibility: true, signalIcon: false }));
                return
            }

            const formData = new FormData()

            formData.append("file", file[0])
            formData.append("channel_id", Channels.WhatsApp);

            const headers = {
                ...UserService.getHeaders(),
                "Content-Type": "multipart/form-data; charset=utf-8"
            };


            AppRequesteConst.Post(
                '/panel/upload/attachment', formData, { headers },
                (response: Object) => {
                    return response;
                },
                (data: { data: { id: string; }; }) => {
                    dispatch(setShowAlertFeedback({ message: "Upload feito com sucesso", visibility: true, signalIcon: true }));
                    setDeleted(false)
                    setIsClicked(true)
                    setFile_id(data.data.id);
                    setFiles(file)
                },
                (error: { response: { status: number; data: { message: any[], code_cxpress: number; }; }; }) => {
                    setIsClicked(true);

                    if (error.response?.data?.message && error.response.data.message[0]) {
                        dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                    } else {
                        dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }));
                    }

                    if (error.response.status === 422) {
                        dispatch(setShowAlertFeedback({ message: "Erro no upload do arquivo", visibility: true, signalIcon: false }));
                    }
                    else if (error.response.status === 400) {
                        dispatch(setShowAlertFeedback({ message: "A template não possui todos os parâmetros necessários.", visibility: true, signalIcon: false }));
                        deleteFile(files)
                        setFiles([])
                        setDeleted(true)
                    }
                    else {
                        dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado", visibility: true, signalIcon: false }));
                    }
                }, navigate, dispatch, setIsLoading,
            );
        },
        onDropRejected: (reject) => {
            if (reject[0].errors[0].code === "file-too-large") {
                dispatch(setShowAlertFeedback({ visibility: true, message: "O tamanho do arquivo excede 5MB.", signalIcon: false }));
            }
        },
    });

    const showFiles = files.map((file, index) => {
        if (!deleted) {
            return (
                <span key={index}
                    style={{
                        background: '#E8E8E8 0% 0% no-repeat padding-box',
                        borderRadius: '3px',
                        opacity: '1',
                        marginRight: '0.5vw',
                        marginBottom: '1vh',
                        whiteSpace: 'pre', display: 'inline-block'
                    }}>
                    <svg id="Grupo_11419" data-name="Grupo 11419" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path id="Caminho_9725" data-name="Caminho 9725" d="M0,0H24V24H0Z" fill="none" />
                        <path id="Caminho_9726" data-name="Caminho 9726" d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Caminho_9727" data-name="Caminho 9727" d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <line id="Linha_391" data-name="Linha 391" y2="6" transform="translate(12 11)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Caminho_9728" data-name="Caminho 9728" d="M9,14l3-3,3,3" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>
                    <span
                        style={{
                            textAlign: 'left',
                            font: 'var(--px10_13-font-Roboto)',
                            letterSpacing: '0px',
                            color: '#1E1E1E',
                            opacity: '1',
                        }}
                    >
                        {file.name}
                    </span>
                    {file ?
                        <svg name={file.name} onClick={() => deleteFile(file.name)} style={{ cursor: 'pointer', marginRight: '3px', marginLeft: '3px' }} id="Grupo_11428" data-name="Grupo 11428" xmlns="http://www.w3.org/2000/svg" width="14.438" height="14.438" viewBox="0 0 14.438 14.438">
                            <path id="Caminho_9729" data-name="Caminho 9729" d="M0,0H14.438V14.438H0Z" fill="none" />
                            <line id="Linha_392" data-name="Linha 392" x1="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            <line id="Linha_393" data-name="Linha 393" x2="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        </svg>
                        :
                        <Spinner animation="border" role="status" style={{ marginRight: '3px', marginLeft: '3px', width: "14.438px", height: "14.438px" }} >
                        </Spinner>
                    }
                </span>
            )
        }
    });

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ textAlign: 'left', font: 'normal normal bold calc(0.2em + 1vw) Montserrat', letterSpacing: '0px', color: 'black', opacity: '1', display: 'flex', alignItems: 'center' }}>Configurações de Broker</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        Webhook: Webhook,
                        description: description,
                        addres: addres,
                        email: email
                    }}
                    validate={(values) => {
                        let errors = {};
                        setWebhook(values.Webhook)
                        setAddres(values.addres)
                        setDescription(values.description)
                        setEmail(values.email)

                        if (!values.email) {
                            setShowFeedbackEmail(false)
                        }
                        else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(
                                values.email
                            )
                        ) {
                            errors["email"] = 'E-mail inválido'
                            setShowFeedbackEmail(true)
                        } else {
                            setShowFeedbackEmail(false)
                        }

                        if(validateUrl(values.Webhook)) {
                            errors["Webhook"] = ''
                            setShowFeedbackWebhook(true);
                        } else {
                            setShowFeedbackWebhook(false);
                        }
                        return errors
                    }}
                    onSubmit={async () => {
                        let JsonSend = {
                            profile: {
                            }

                        }

                        if (addres) {
                            JsonSend['profile']['address'] = addres
                        }
                        if (description) {
                            JsonSend['profile']['description'] = description
                        }
                        if (email) {
                            JsonSend['profile']['email'] = email
                        }
                        if (Webhook !== '') {
                            JsonSend["webhook"] = Webhook
                        }
                        if (urls[0].length > 0) {
                            JsonSend['profile']['websites'] = urls
                        }
                        if (file_id) {
                            JsonSend['profile_picture_id'] = file_id
                        }

                        const headers = UserService.getHeaders()
                        await AppRequesteConst.Post(
                            `/channel-whatsapp/broker-config/${currentWhatsappId}`, JsonSend, { headers },
                            (response: Object) => {

                            },
                            (data) => {
                                dispatch(setShowAlertFeedback({ message: "Alterações salvas com sucesso.", visibility: true, signalIcon: true }))
                                onHide()
                            },
                            (error: { response: { status: number; data: { status: number; code_cxpress: number, message: { context: { label: string } } } } }) => {
                                if (error.response.status === 400) {
                                    dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }))
                                } 
                                else if (error?.response?.status === 422) {
                                    dispatch(setShowAlertFeedback({ message: `Campo com formato inválido`, visibility: true, singnalIcon: false }))
                                }
                            }, navigate, dispatch, setIsLoading
                        )

                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate validated={validated}>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <Col>
                                    <Form.Label htmlFor="Webhook">
                                        Webhook
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            id="Webhook"
                                            isInvalid={showFeedbackWebhook}
                                            className="form-control-Default"
                                            placeholder="https://exemplo"
                                            aria-label="Informe o webhook"
                                            aria-describedby="Informe o webhook"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.Webhook}
                                        >
                                        </FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackWebhook">
                                            {errors.Webhook}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row style={styles.textFontGlay} className="mb-3 d-flex justify-content-center">
                                <Col sm={true}>
                                    <Form.Label htmlFor="basic-url">Foto de perfil</Form.Label>
                                    <div style={{
                                        width: '102%',
                                        display: 'flex',
                                        overflowY: 'hidden',
                                        marginLeft: '-0.6vw',
                                        justifyContent: 'left'
                                    }}>
                                        <div {...getRootProps({ className: 'dropzone' })}
                                            style={allStyles.dragDropzone}>
                                            <input {...getInputProps({})} />
                                            {files.length > 0 && !deleted ?
                                                <div name="showFilesName" className="ps-2 py-1 pt-2">
                                                    {setErrorSelectRecipients(false)}
                                                    <div style={{ display: 'block' }}>
                                                        {showFiles}
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    <div>Faça upload da foto de perfil</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {errorSelectRecipients && <p style={{ padding: '0', color: '#dc3545', fontSize: ".875em" }}> Preencha o campo </p>}
                                </Col>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <FormLabel htmlFor="addres">
                                    Endereço
                                </FormLabel>
                                <InputGroup>
                                    <FormControl
                                        id="addres"
                                        isInvalid={showFeedbackAddres}
                                        className="form-control-Default"
                                        placeholder="Informe o endereço"
                                        aria-label="Informe o endereço"
                                        aria-describedby="Informe o endereço"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.addres}
                                        maxLength={256}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackAddres">
                                        {errors.addres}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <FormLabel>
                                    Descrição
                                </FormLabel>
                                <InputGroup>
                                    <FormControl
                                        id="description"
                                        isInvalid={showFeedbackDescription}
                                        className="form-control-Default"
                                        placeholder="Informe a descrição"
                                        aria-describedby="Informe a descrição"
                                        aria-label="Informe a descrição"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.description}
                                        maxLength={512}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackDescription">
                                        {errors.description}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <FormLabel>
                                    Email
                                </FormLabel>
                                <InputGroup>
                                    <FormControl
                                        id="email"
                                        isInvalid={showFeedbackEmail}
                                        className="form-control-Default"
                                        placeholder="Informe o email"
                                        aria-describedby="Informe o email"
                                        aria-label="Informe o email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        maxLength={128}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackEmail">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </InputGroup>

                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <FormLabel>
                                    Websites
                                </FormLabel>
                                {urls.map((url, index) => (
                                    <InputGroup key={index} className="mb-2 d-flex align-items-center">
                                        <FormControl
                                            isInvalid={validateUrl(url)}
                                            className="form-control-Default"
                                            placeholder="https://exemplo"
                                            aria-describedby="Informe a(s) URL(s)"
                                            aria-label="Informe a(s) URL(s)"
                                            onChange={(e) => handleChangeUrl(index, e)}
                                            value={url}
                                            maxLength={256}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors[index]}
                                        </Form.Control.Feedback>
                                        {urls.length > 1 && (
                                            <Button size="lg" className="buttonWhiteBroker" style={{ width: '22%', marginLeft: '0.3rem' }} onClick={() => removeUrl(index)}>
                                                Remover
                                            </Button>
                                        )}
                                    </InputGroup>
                                ))}
                                {urls.length < 2 && (
                                    <>
                                        <Col xs={12} className="d-flex justify-content-center">
                                            <Button size="lg" className="buttonBlue" style={{ width: '28%' }} onClick={addUrl}>
                                                Adicionar URL
                                            </Button>
                                        </Col>
                                    </>
                                )}
                            </Row>
                            <Row className="d-flex justify-content-center mt-4">
                                <Col xs={6} className="d-flex justify-content-start">
                                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => onHide()}>Cancelar</Button>
                                </Col>
                                <Col xs={6} className="d-flex justify-content-end">
                                    <Button size="lg" className="buttonBlue" style={{ width: '56%' }} type="submit" disabled={!isValid}>Salvar</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal >
    )
}

export default ModalEditBroker