import React, { FC, useEffect, useState } from 'react';
import AttachmentsInMessage from '.';
import { AttachmentsInMessageControllerProps } from './indexModel';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { getToken } from '../../../../../store/token';
import { useDropzone } from 'react-dropzone';
import { setShowAlertFeedback } from '../../../../../../store/internal';
import { Channels } from '../../../../../../core/enums/channels';
import { getToken } from '../../../../../../store/control_panel';
import { useTranslation } from 'react-i18next';
import { verifyCode } from '../../../../../../services/codeCxpressInterface';

const AttachmentsInMessageController: FC<AttachmentsInMessageControllerProps> = (props) => {
    const AppRequesterConst = new AppRequesterController();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [loading, setIsLoading] = useState(false);

    const [gfids, setGfids] = useState([]);
    const [files, setFiles] = useState([]);
    const [fileLoading, setFileLoading] = useState([]);
    const [lastId, setLastId] = useState(null);

    const [loadPreviewFiles, setLoadPreviewFiles] = useState(true);

    const values = {
        token: {
            value: useSelector(getToken),
        }
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        multiple: false,
        minSize: 1,
        maxSize: 15728640,
        validator: (file) => {
            let findedFile = files.find(item => item.name === file.name);

            if (findedFile) {
              return {
                code: "file-name-finded",
                message: `Arquivo com mesmo nome encontrado`
              };
            }

            return null
        },
        disabled: false,
        /* noClick: isClicked,
        noDrag: isClicked, */
        onDrop: (dropedFiles) => {
            if (files.length === 0) {
                const formData = new FormData();
            const file = dropedFiles[0];

            formData.append("file", file);
            formData.append("channel_id", Channels.WhatsApp);
        
            const headers = {
                "Content-Type": "multipart/form-data; charset=utf-8",
                "Authorization": "Bearer " + values.token.value
            };

            fileLoading[files.length] = true;
            setFileLoading(fileLoading);

            AppRequesterConst.Post(
                '/panel/upload/attachment', formData, { headers },
                (response: Object) => {
                    return response;
                },
                (data: { data: { id: string; name: string; }; }) => {
                    dispatch(setShowAlertFeedback({ message: t("home_message_trigger.modal_register_message_trigger.upload_sucess"), visibility: true, signalIcon: true }));
                    setLastId(data.data.id);

                    // adicionando o gfid junto com os demais atributos
                    setFiles((allFiles) => {
                        allFiles.map((file, index) => { 
                            if (file.name === data.data.name)
                                allFiles[index] = Object.assign(file, { gfid: data.data.id });
                        });
                        return allFiles;
                    });
                },
                (error: { response: { status: number; data: { message: any[]; code_cxpress: number }; }; }) => { 
                    if (error.response?.data?.message && error.response.data.message[0]) {
                        dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                    } else {
                        dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }));
                    }
                }, navigate, dispatch, setIsLoading, 
                () => { 
                    fileLoading[files.length] = false;
                    setFileLoading(fileLoading);
                }
            );
            } else {
                dispatch(setShowAlertFeedback({ visibility: true, message: "Remova o arquivo anterior.", signalIcon: false }));
            }
        },
        onDropRejected: (reject) => {
          if (reject[0].errors[0].code === "file-too-large") {
            dispatch(setShowAlertFeedback({ visibility: true, message: "O tamanho do arquivo excede 15MB.", signalIcon: false }));
          } else if (reject[0].errors[0].code === "file-name-finded") {
            dispatch(setShowAlertFeedback({ visibility: true, message: "Arquivo com mesmo nome encontrado.", signalIcon: false }));
          }
        },
        accept: { 
            // imagens que foram colocadas especificamente para imagens
            'image/gif': ['.gif'],
            'image/vnd.microsoft.icon': ['.ico'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/bmp': ['.bmp'],
            // 'image/tiff': ['.tif', '.tiff'],

            /* 'video/mpeg': ['.mpeg'],
            'video/mp4': ['.mp4'],
            'audio/mpeg': ['.mpeg', '.mp3'],
            'audio/aac': ['.aac'],
            'audio/amr': ['.amr'],
            'image/jpeg': ['.jpeg'],
            'image/png': ['.png'],
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.ms-powerpoint': ['.ppt'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'], */
         },
    });

    // passa os gfids com a callback
    useEffect(() => {
        props.setAttachmentsFilesIds(gfids);
    }, [gfids]);

    // adiciona o último gfid de um upload na listagem
    useEffect(() => {
        if (lastId !== null) {
            setGfids([...gfids, lastId]);
            setLastId(null);
        } 
    }, [lastId]);

    useEffect(() => {
        setLoadPreviewFiles((load) => {
            // carregando arquivos salvos
            if (load && props.previewFiles) {
                let files = props.previewFiles.map((item) => {
                    return { name: item.filename, url: item.url, gfid: item._id, isLoading: false };
                });
                
                setFiles(files);
    
                setGfids(files.map((item) => { return item.gfid }));
                setLoadPreviewFiles(false);
            } else { // edição normal dos arquivos
                let allFiles = files.concat(acceptedFiles);
    
                allFiles = [...new Map(allFiles.map(file => [file.name, file])).values()];
            
                allFiles.map(file => {
                    const returnedTarget = Object.assign(file, { isLoading: true });
                    file = returnedTarget;
                });
            
                if (allFiles.length > 1) {
                    allFiles = [allFiles[0]];
                }

                setFiles(allFiles);
            
                acceptedFiles.forEach((file, i) => { acceptedFiles.pop() });
            } 
            return load;
        });   

    }, [acceptedFiles, props.previewFiles]);

    const deleteFile = (fileName: any[]) => {
        let newFiles = files.filter((item) => item.name !== fileName);
        acceptedFiles.forEach((file, i) => { acceptedFiles.pop() });
        setFiles(newFiles);

        // gfids após deletar um arquivo
        let newGfids = newFiles.map((file, index) => { 
            if (file?.gfid)
                return file.gfid;
            else if (file?._id)
                return file._id;
        });
        setGfids(newGfids);
    }

    const handleDownloadFile = (file: any) => {
        const link = document.createElement('a');
        link.href = file.url;
        link.target = '_blank';
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    
    return <AttachmentsInMessage isLoading={loading} files={files} setFiles={setFiles} fileLoading={fileLoading} handleDownloadFile={handleDownloadFile} deleteFile={deleteFile} getRootProps={getRootProps} getInputProps={getInputProps} selectedChannelId={Channels.WhatsApp} />
}   

export default AttachmentsInMessageController;