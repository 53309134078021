import React, { useState, useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getShowEditTicketsModal, getOrderByList, getMute, setShowConfirmationModal, setShowAlertFeedback, setUpdateButtonNumberOfTickets } from '../../../../store/internal'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { DataTicketsExport, TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../store/company";
import { getIdEmployee } from "../../../../store/employee";
import { getIdUser, getNameUser, getProfileId, getUserLastName } from "../../../../store/user";
import { getToken } from "../../../../store/token";
import BackendConstants from "../../../../core/constants/backend-constants";
import UserService from "../../../../services/user-service";
import { IModalListTicketsController } from "./indexModel";
import saveAs from "file-saver";
import { verifyCode } from "../../../../services/codeCxpressInterface";
import ModalListTickets from ".";
import Utils from "../../../../core/shared/utils";

const ModalListTicketsController: FC<IModalListTicketsController> = (props) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)

    // const [modalShow, setModalShow] = useState(props.visibility);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appRequesterConst = new AppRequesterController();

    const [tickets, setTickets] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, /* setLimit */] = useState(BackendConstants.limit);
    // const [currentParams, setCurrentParams] = useState(props.params);

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId),
            lastname: useSelector(getUserLastName),
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
            getOrderByList: useSelector(getOrderByList),
        }, mute: {
            value: useSelector(getMute)
        }

    };

    useEffect(() => {
        const controller = new AbortController();

        if (props.visibility) {
            setCurrentPage((page) => {
                getAllTickets(Object.assign(props.params, { page: page, limit: limit }), controller);
                return page;
            });
        } else {
            setTickets([]);
            setCurrentPage(1);
        }

        return () => {
            controller.abort();
        }
    }, [currentPage, props.params, props.visibility]);

    const hide = () => {
        props.setVisibility(false);
    }

    const updateListFunction = () => {
        setCurrentPage((page) => { return page + 1; })
    }

    const getAllTickets = async (params: { limit: number; page: number; }, controller: AbortController) => {
        const headers = UserService.getHeaders(), signal = controller.signal;

        params = Object.assign(params, { is_all_status: true });

        await appRequesterConst.Get(
            '/ticket/dashboard/get-tickets', { headers, signal, params: params },
            (response: Object) => { },
            (data) => {
                if (data.status === 200 && data?.data?.tickets?.length > 0) {
                    setTickets((state) => {
                        return state.concat(data.data.tickets);
                    });
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const exportTicketsCsvConfirmation = () => {
        dispatch(setShowConfirmationModal({
            visibility: true, text: { "title": t('home_tickets.list_tickets.export_to_csv.title'), "body": t('home_tickets.list_tickets.export_to_csv.body'), 'id': '', "buttonConfirmationText": t('home_tickets.list_tickets.export_to_csv.button_confirmation'), "twoButton": t('home_tickets.list_tickets.export_to_csv.two_button'), "isExport": true },
            functionConfirmation: () => exportTicketsCsv(true),
            functionConfirmation2: () => exportTicketsCsv(false)
        }));
    }

    const exportTicketsCsv = async (hasAdditionalContent: boolean) => {
        let ticketsId = [];

        tickets.map((ticket) => {
            ticketsId.push(ticket.id);
        });

        const headers = UserService.getHeaders()
        const request = {
            tickets: ticketsId,
            has_additional_content: hasAdditionalContent
        };

        await appRequesterConst.Post(
            '/ticket/export', request, { headers },
            (response: Object) => {},
            (data: DataTicketsExport) => {
                if (data.status === 200 && data.data) {
                    if (hasAdditionalContent) {
                        const arrayBuffer = new Uint8Array(data.data.file.content.data).buffer;
                        const blob = new Blob([arrayBuffer], {
                            type: 'application/zip'
                        });
                        saveAs(blob, data.data.file.filename)
                        dispatch(setShowAlertFeedback({ message: t('dashboard.modal_list_tickets.exported_message'), visibility: true, signalIcon: true }));
                    } else {
                        var decoder = new TextDecoder();
                        var uintData = Uint8Array.from(data.data.file.content.data);
                        var arrayBufferData = uintData.buffer;
                        var decoded = decoder.decode(arrayBufferData);
                        var hiddenElement = document.createElement('a');
                        hiddenElement.href = `data:${data.data.file.mimetype};charset=utf-8,` + encodeURI(decoded);
                        hiddenElement.target = '_blank';
                        hiddenElement.download = data.data.file.filename;
                        hiddenElement.click();
                        dispatch(setShowAlertFeedback({ message: t('dashboard.modal_list_tickets.exported_message'), visibility: true, signalIcon: true }));
                    }
                }
            },
            (error) => {
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            },
            navigate,
            dispatch,
            setIsLoading,
            () => {
                dispatch(setUpdateButtonNumberOfTickets(true));
            },
        );
    }

    const formatedDate = (date: Date) => {
        const day = Utils.addLeftZeros(date.getDate());
        const month = Utils.addLeftZeros(date.getMonth() + 1);
        const year = date.getFullYear();

        return day + "/" + month + "/" + year;
    }

    return <ModalListTickets 
        modalShow={props.visibility}
        hide={hide}
        t={t}
        tickets={tickets}
        setTickets={setTickets}
        updateListFunction={updateListFunction}
        exportTicketsCsvConfirmation={exportTicketsCsvConfirmation}
        formatedDate={formatedDate}
        params={props.params} 
        isLoading={isLoading}    
    />
}

export default ModalListTicketsController;