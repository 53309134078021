import { FC } from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import Loading from "../../../components/loading";
import { IHomeTasks } from "./indexModel";
import CallNotificationBar from "../components/callNotificationBar";
import { useSelector } from "react-redux";
import { getShowAcquirePlatformComponent, getShowCallNotificationBar } from "../../../store/internal";
import AcquirePlatformNotificationBar from "../components/acquirePlatformNotificationBar";
import ListOfTasksController from "./listOfTasks/indexController";
import FilterOptionComponentController from "../components/filterOptionComponent/indexController";
import ButtonStatusAgent from "../components/buttonStatusAgent";
import ButtonListNotificationController from "../../../components/buttonListNotification/indexController";

const HomeTasks: FC<IHomeTasks> = (props) => {
    return (
        <Container fluid className="nopadding">
            <Loading visibility={props?.isLoading} />
            {/* <ModalEditTaskController setIsLoading={props.setIsLoading} /> */}
            {/* <ModalEditTaskController setIsLoading={props.setIsLoading} dataEdit={props.dataEdit} getTasks={props.getTasks} /> */}
            <div className="wrapper">
                {/* <SidebarController currentPage={SidebarButtonPage.Tasks} /> */}
                <div className="content pt-0 ps-0">
                    <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)} />
                    <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                    <Row className="nopadding mt-3 ps-3">
                        <Col md={7} style={{ marginRight: '-8vw', marginBottom: '2vh' }}>
                            <Row>
                                <Col className="d-flex justify-content-start align-self-start">
                                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>{props.t('home_tasks.general.welcome_main')}</div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{props.t('home_tasks.general.welcome_sub')}</div>
                            </Row>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            {/* <ButtonSearchCleanInput inputPlaceholder={"pesquisar tarefa"} filteringTickets={(auxObj, action) => props?.filteringTasks(auxObj, action)} setCurrentFilterSearch={props?.setCurrentFilterSearch} currentFilterSearch={props?.currentFilterSearch} ></ButtonSearchCleanInput> */}
                            <ButtonListNotificationController />
                            <ButtonStatusAgent />
                        </Col>
                    </Row>
                    {
                        props.viewFilter === true &&
                        <div className='FilterTicketsRow ps-3' style={{ margin: '2vh 0.5vw 0px', padding: '0' }}>
                            <ListGroup variant="flush" >
                                <ListGroup.Item style={{ backgroundColor: 'rgba(207, 227, 255,0.25)', padding: '0.6%' }}>
                                    <FilterOptionComponentController
                                        optionNames={["requester_task", "assigned_task", "TicketTaskStatus", "TicketTaskTags"]}
                                        currentState={props?.currentState}
                                        setCurrentState={props?.setCurrentState}
                                        values_user={props?.values.user}
                                        filteringTickets={(e, auxObj, action) => props?.filteringTasks(e, auxObj, action)}
                                        cleanList={() => props?.cleanList()}
                                        values_token_value={props?.values.token.value}
                                        values_company_id={props?.values.company.id}
                                        yoursTickets={false}
                                        screen={'home_tasks'}
                                    />
                                </ListGroup.Item>
                            </ListGroup>
                        </div>
                    }
                    <ListOfTasksController tasks={props?.tasks} getTasks={props?.getTasks} currentPage={props.currentPage} setCurrentPage={props.setCurrentPage} setViewFilter={props?.setViewFilter} currentParams={props.currentParams} callbackModalTasks={props.callbackModalTasks} />
                </div>
            </div>
        </Container>
    );
}

export default HomeTasks