import { Button, Popover } from "react-bootstrap";
import constsRouters from "../../../../../routes/constsRouter";
import Utils from "../../../../../core/shared/utils";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ListTagsInterface } from "./indexModel";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { getShowEditTagsModal, setShowAlertFeedback, setShowConfirmationModal, setShowEditTagsModal } from "../../../../../store/internal";
import ListTags from ".";
import { useTranslation } from "react-i18next";
import { verifyCode } from "../../../../../services/codeCxpressInterface";
import UserService from "../../../../../services/user-service";
import { TokenInterface } from "../../../../../services/requestsInterfacesModel";

const ListOfTagsController: FC<ListTagsInterface> = (props) => {

    const AppRequesterConst = new AppRequesterController();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const values: TokenInterface = {
        internal: {
            getShowEditTagsModal: useSelector(getShowEditTagsModal),
        }
    }

    const [currentTagId, setCurrentTagId] = useState('');
    const [currentTagName, setCurrentTagName] = useState('');
    const [currentTagData, setCurrentTagData] = useState(null);
    const [currentTagIsActive, setCurrentTagIsActive] = useState(null);
    const [deactivateListTags, setDeleteListTags] = useState([])
    const [tagsDeletedNames, setTagsDeletedNames] = useState([])

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        Utils.changeActiveScrollBar("listOfTags");
    }, [props.datas]);

    useEffect(() => {
        if (values.internal.getShowEditTagsModal) {
            dispatch(setShowEditTagsModal(false));
        }
    }, []);

    // useEffect(() => {
    //     if (props.datas !== undefined) {
    //         for (let i = 0; i < props.datas.length; i++) {
    //             if (document.getElementById("checkboxesAll" + i) != null) {
    //                 (document.getElementById("checkboxesAll" + i) as HTMLInputElement).checked = false;
    //                 setDeleteListTags([])
    //             }
    //         }
    //         setElementDisplayById('buttonDeleteAllSelected', 'none');
    //         setElementDisplayById('deactivationButton', 'none');
    //         setElementDisplayById('deleteTags', 'none');
    //     }

    //     Utils.changeActiveScrollBar("listOfTags")
    // }, [props.datas])

    const activeTagFunction = async (active: boolean) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + props.token
        }

        let JsonSend = {
            "id": currentTagId.split("*ID*")[1],
            "name": currentTagName,
            "active": active
        }


        await AppRequesterConst.Put(
            props?.tagConsumer ? '/consumer-tag' : '/tag', JsonSend, { headers },
            (response: Object) => { },
            (data: Object) => {
                if (active) {
                    dispatch(setShowAlertFeedback({ message: t('home_tags.list_activeTagFunction_sucess'), visibility: true, signalIcon: true }));
                } else {
                    dispatch(setShowAlertFeedback({ message: t('home_tags.list_disableTagFunction_sucess'), visibility: true, signalIcon: true }));
                }
                props.updateListAfterDelete();
                setElementDisplayById('buttonDeleteAllSelected', 'none');
                setElementDisplayById('deactivationButton', 'none');
                setElementDisplayById('deleteTags', 'none');
            },
            (error: { response: { status: number; data: { code_cxpress: number } }; }) => {
                // dispatch(setShowAlertFeedback({ message: t('home_tags.list_activeTagFunction_error'), visibility: true, signalIcon: false }));
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedback(true);
                    }
                    if (error.response.status === 401) {
                        //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedbackPassword(true);
                    }
                }
            }, navigate, dispatch, setIsLoading
        );
    }


    const setCurrentTagInfo = (e: { currentTarget: { id: string; name: string; }; }) => {
        setCurrentTagId(e.currentTarget.id);
        setCurrentTagName(e.currentTarget.name);
        setCurrentTagIsActive(e.currentTarget.id.split("*ID*")[2] === "true");
        handleSetCurrentTagData(e.currentTarget.id.split("*ID*")[1]);
    }

    const handleSetCurrentTagData = (selectedTagId: string) => {
        let dataTag = props.datas.filter((item: { id: string; }) => item.id === selectedTagId)[0];
        setCurrentTagData(dataTag);
    }

    const viewTagPage = (e: any) => {
        // using the button, not item click
        if (e.target && e.target.name === "ButtonViewRelatedTags") {
            navigate(constsRouters.routers.configurationTagsView.path, { state: { id: currentTagId.split("*ID*")[1], name: currentTagName, active: currentTagIsActive, consumers: props?.tagConsumer ? true : false } });
        } else {
            navigate(constsRouters.routers.configurationTagsView.path, { state: { id: e.currentTarget.id.split("*ID*")[1], name: e.currentTarget.id.split("*ID*")[0], active: e.currentTarget.id.split("*ID*")[2], consumers: props?.tagConsumer ? true : false } });
        }
    }

    const massActivationTags = async (deactivateListTags) => {
        const headers = UserService.getHeaders()

        let JsonSend = {
            "ids": deactivateListTags.length > 0 ? deactivateListTags : [],
            "active": true
        }

        await AppRequesterConst.Put(
            'tag/status', JsonSend, { headers },
            (response: Object) => {},
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_tags.show_activeTagsFunction_success'), visibility: true, signalIcon: true }));
                props.updateListAfterDelete();
                setElementDisplayById('buttonDeleteAllSelected', 'none');
                setElementDisplayById('deactivationButton', 'none');
                setElementDisplayById('deleteTags', 'none');
            },
            (error: { response: { status: number; data: { code_cxpress: number } } }) => {
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visbility: true, signalIcon: false }));  

            }, navigate, dispatch, setIsLoading
        )
    }

    const massDeactivationTags = async (deactivateListTags) => {
        const headers = UserService.getHeaders()

        let JsonSend = {
            "ids": deactivateListTags.length > 0 ? deactivateListTags : [],
            "active": false
        }

        await AppRequesterConst.Put(
            'tag/status', JsonSend, { headers },
            (response: Object) => {},
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_tags.show_disableTagsFunction_success'), visibility: true, signalIcon: true }));
                props.updateListAfterDelete()
                setElementDisplayById('buttonDeleteAllSelected', 'none');
                setElementDisplayById('deactivationButton', 'none');
                setElementDisplayById('deleteTags', 'none');
            },
            (error: { response: { status: number; data: { code_cxpress: number } } }) => {
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
            }, navigate, dispatch, setIsLoading
        )
    }

    const formatedDate = (date: Date) => {
        const day = Utils.addLeftZeros(date.getDate());
        const month = Utils.addLeftZeros(date.getMonth() + 1);
        const year = date.getFullYear();

        return day + "/" + month + "/" + year;
    }

    const activeTagConfirm = (activeTagFunction: Function) => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t('home_tags.list_activate_title'), "body": t('home_tags.list_activate_body'), "id": currentTagName +  "?", "buttonConfirmationText": t('home_tags.list_activate'), "buttonReturnText": t('home_tags.list_button_return') }, functionConfirmation: activeTagFunction }));
    }

    const disableTagConfirm = (disableTagFunction: Function) => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t('home_tags.list_inactivate_title'), "body": t('home_tags.list_inactivate_body'), "id": currentTagName + "?", "buttonConfirmationText": t('home_tags.list_inactivate'), "buttonReturnText": t('home_tags.list_button_return') }, functionConfirmation: disableTagFunction }));
    }

    const activationAllSelected = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t('home_tags.modal_active_title'), "body": t('home_tags.modal_active_body'), 'id': '', "buttonConfirmationText": t('home_tags.list_activate'), 'buttonReturnText': t('home_tags.list_button_return') }, functionConfirmation: () => massActivationTags(deactivateListTags) }))
    }

    const deactivationAllSelected = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t('home_tags.modal_inactivate_title'), "body": t('home_tags.modal_inactivate_body'), 'id': '', "buttonConfirmationText": t('home_tags.list_inactivate'), 'buttonReturnText': t('home_tags.list_button_return') }, functionConfirmation: () => massDeactivationTags(deactivateListTags) }))
    }

    const deleteAllTag = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t('home_tags.modal_remove_tags_title'), "body": t('home_tags.modal_remove_tags_body'), 'id': '', "buttonConfirmationText": t('home_tags.modal_confirm_delete'), 'buttonReturnText': t('home_tags.list_button_return') }, functionConfirmation: () => deleteTags(deactivateListTags) }))
    }

    const deleteTagConfirmation = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t('home_tags.modal_remove_title'), "body": t('home_tags.modal_remove_tag_body'), "id": currentTagName + "?", "buttonConfirmationText": t('home_tags.modal_confirm_delete'), 'buttonReturnText': t('home_tags.list_button_return') }, functionConfirmation: () => deleteTag() }))
    }

    const showEditTagModal = () => {
        dispatch(setShowEditTagsModal(true));
    }

    const popover = (active: boolean) => {
        return (
            <Popover id={currentTagId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
                <Popover.Body className="nopadding">
                    {props?.consumer ?

                        <Button onClick={() => { showEditTagModal(); props?.setConsumer(true) }} className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_tags.list_popover_edit_tag')}</Button>
                        :
                        <Button onClick={() => showEditTagModal()} className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_tags.list_popover_edit_tag')}</Button>

                    }
                    <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%' }}></div>
                    {
                        active ?
                            <Button onClick={() => disableTagConfirm(() => activeTagFunction(false))} className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_tags.list_inactivate_title')}</Button>
                            :
                            <Button onClick={() => activeTagConfirm(() => activeTagFunction(true))} className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_tags.list_activate_title')}</Button>
                    }

                    <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                    <Button onClick={() => deleteTagConfirmation()} className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_tags.list_popover_delete_tag')}</Button>
                    <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                    {props?.tagConsumer ?
                        <Button onClick={(e) => viewTagPage(e)} name="ButtonViewRelatedTags" className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_tags.list_popover_view_consumers')}</Button>
                        :
                        <Button onClick={(e) => viewTagPage(e)} name="ButtonViewRelatedTags" className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_tags.list_popover_view_ticket')}</Button>
                    }
                </Popover.Body>
            </Popover>
        )
    };

    const allCheckboxesClick = (e: { currentTarget: { checked: boolean } }) => {
        let aux = [];
        if (e.currentTarget.checked === true) {
            for (let i = 0; i < props.datas.length; i++) {
                if ((document.getElementById("checkboxesAll" + i) as HTMLInputElement) != null) {
                    (document.getElementById("checkboxesAll" + i) as HTMLInputElement).checked = true;
                    aux.push((document.getElementById("checkboxesAll" + i) as HTMLInputElement).parentElement.parentElement.parentElement.parentElement.id.split("*ID*")[1]);
                    setDeleteListTags(aux)
                }
            }
            setElementDisplayById('buttonDeleteAllSelected', 'block');
            setElementDisplayById('deactivationButton', 'block');
            setElementDisplayById('deleteTags', 'block');
        } else {
            for (let i = 0; i < props.datas.length; i++) {
                if ((document.getElementById("checkboxesAll" + i) as HTMLInputElement) != null) {
                    (document.getElementById("checkboxesAll" + i) as HTMLInputElement).checked = false;
                    setDeleteListTags(aux);
                }
            }
            setElementDisplayById('buttonDeleteAllSelected', 'none');
            setElementDisplayById('deactivationButton', 'none');
            setElementDisplayById('deleteTags', 'none')
        }
    }

    const checkboxesClick = () => {
        let count = 0;
        let aux = [];
        let auxName = [];

        for (let i = 0; i < props.datas.length; i++) {
            let checkTag = document.getElementById("checkboxesAll" + i);
            if (checkTag && (checkTag as HTMLInputElement).checked === true) {
                count++
                aux.push((document.getElementById("checkboxesAll" + i) as HTMLInputElement).parentElement.parentElement.parentElement.parentElement.id.split("*ID*")[1]);
                setDeleteListTags(aux);
                auxName.push((document.getElementById("checkboxesAll" + i) as HTMLInputElement).parentElement.parentElement.parentElement.parentElement.id.split("*ID*")[0]);
                setTagsDeletedNames(auxName);
            }
        }

        if (count !== 0) {
            setElementDisplayById('buttonDeleteAllSelected', 'block');
            setElementDisplayById('deactivationButton', 'block');
            setElementDisplayById('deleteTags', 'block');
        } else {
            setElementDisplayById('buttonDeleteAllSelected', 'none');
            setElementDisplayById('deactivationButton', 'none');
            setElementDisplayById('deleteTags', 'none');
        }
    }


    const deleteTags = async (ids: any[]) => {
        const headers = UserService.getHeaders()
        const info = {
            headers,
            data: {
                type: props?.tagConsumer ? 'consumer' : 'ticket',
                ids: ids
            }
        }
        
        await AppRequesterConst.Delete(
            '/tag', info,
            (response: Object) => { },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_tags.show_removeTagsFunction_success'), visibility: true, signalIcon: true }));
                props.updateListAfterDelete();
                setElementDisplayById('buttonDeleteAllSelected', 'none');
                setElementDisplayById('deactivationButton', 'none');
                setElementDisplayById('deleteTags', 'none');
            },
            (error: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_tags.list_disableTagFunction_error'), visibility: true, signalIcon: false }));
            },
            navigate,
            dispatch,
            setIsLoading,
        );
    }

    const deleteTag = async () => {
        const headers = UserService.getHeaders()

        const info = {
            headers,
            data: {
                ids: [currentTagId.split("*ID*")[1]],
            }
        }

        await AppRequesterConst.Delete(
            props?.tagConsumer ? '/consumer-tag' : '/tag', info,
            (response: Object) => {},
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_tags.show_removeTagFunction_success'), visibility: true, signalIcon: true }));
                props.updateListAfterDelete();
                setElementDisplayById('buttonDeleteAllSelected', 'none');
                setElementDisplayById('deactivationButton', 'none');
                setElementDisplayById('deleteTags', 'none');
            },
            (error: { response: { status: number; data:{code_cxpress: number, message: string} }; }) => {
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            }, navigate, dispatch, setIsLoading
        );
    }

    const setElementDisplayById = (id: string, value: string) => {
        const exists_element = document.getElementById(id);

        if (exists_element) {
            document.getElementById(id).style.display = value;
        }
    }

    return <ListTags
        t={t}
        isLoading={isLoading}
        token={props.token}
        callbackModalTags={props.callbackModalTags}
        currentTagData={currentTagData}
        callbackOrderBy={props.callbackOrderBy}
        datas={props.datas}
        updateListfunction={props.updateListfunction}
        HasMoreInformation={props.HasMoreInformation}
        viewTagPage={viewTagPage}
        formatedDate={formatedDate}
        popover={popover}
        setCurrentTagInfo={setCurrentTagInfo}
        activeTagFunction={activeTagFunction}
        showCreateTag={props.showCreateTag}
        allCheckboxesClick={allCheckboxesClick}
        checkboxesClick={checkboxesClick}
        activationAllSelected={activationAllSelected}
        deactivationAllSelected={deactivationAllSelected}
        deleteAllTag={deleteAllTag}
        consumer={props?.tagConsumer}
        setConsumer={props?.setConsumer}
    />

}

export default ListOfTagsController